export const FakeDoctors = [
    {
        "id": "001",
        "name": "Tresa Parker",
        "status": "Acute Care Pediatric Nurse Practitioner",
        "img": "C:\Users\marty\peony\src\Images\tresa.jpg"
    },
    {
        "id": "002",
        "name": "Vera Mano",
        "status": "Family Nurse Practitioner",
        "img": "../../../Images/veraHeadshot.jpg"
    },
    {
        "id": "003",
        "name": "Santa Binte",
        "status": "Supervising Physician",
        "img": "https://i.ibb.co/pz92gsK/d1.png"
    }
]