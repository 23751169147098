import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import food from '../../../Images/food.jpg';
import './About.css';

const About = () => {

    useEffect(() => {
        AOS.init({
                duration: 2000,
            });
        AOS.refresh();
      }, []);
    return (
        <section className="about-wrapper">
            <Container>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <div className="about-left">
                            <img src={food} alt="donto" className="img-fluid animated dontoAnim" />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <div className="about-right mt-5 mt-lg-0">
                            <div className="about-content" data-aos="zoom-in">
                                <h1>Welcome to a Family</h1>
                                <p>Modern Health Meets Modern Convinence</p>
                                <a href='/page/about'>About Us</a>
                            </div>
                            {/* <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>500</span>
                                    <span>+</span>
                                    <p>Happy Patients</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-right">
                                    <span>88</span>
                                    <span>+</span>
                                    <p>Qualified Doctors</p>
                                </div>
                                <div className="single-fun" data-aos="fade-left">
                                    <span>25</span>
                                    <span>+</span>
                                    <p>Years Experience</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-left">
                                    <span>50</span>
                                    <span>+</span>
                                    <p>Dental Awards</p>
                                </div>
                                <span className="line"></span>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;