import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Safety.css';

const Safety = () => {
    return (
        <section className="about-content-sec">
            <Container>
                <Row>
                    <Col md={12} lg={8} lg={{ order: 2 }} className="text-center">
                        <div className="section-title">
                            <h1>Coming Soon!</h1>
                        </div>
                        <p className="w-50 m-auto content-inner">We will be offering additional services in the near future.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6} xl={4} xs={12} >
                        <div className="single-service-box focus">
                            <div className="service-icon">
                                {/* <img src={img} alt="" /> */}
                            </div>
                            <h3>Adult Sick Visits</h3>
                            <p>description</p>
                        </div>
                    </Col>
                    <Col md={6} lg={6} xl={4} xs={12} >
                        <div className="single-service-box focus">
                            <div className="service-icon">
                                {/* <img src={img} alt="" /> */}
                            </div>
                            <h3>Pediatric Sick Visits</h3>
                            <p>description</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Safety;