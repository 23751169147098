export const FakeService = [
    {
        "id": "001",
        "title": "Pediatric Metabolic Health",
        "description": "For individuals, under the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=24714&format=png"
    },
    {
        "id": "002",
        "title": "Adult Metabolic Health",
        "description": "For individuals, over the age of 18, meeting the following criteria",
        "link": "Read More",
        "img": "https://img.icons8.com/?size=256&id=lXcsKgjNtoiK&format=png"
    },
    
]