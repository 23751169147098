import "@fontsource/josefin-sans";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import doctorfinding from '../../../Images/doctorfinding.c2532ac3.png';
import heroTeeth from '../../../Images/hero-theeth.54c2c4e9.png';
import scale from '../../../Images/scale.jpg';
import './Banner.css';



const Banner = () => {
    return (
            <section className="single-hero-slide text-white d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} sm={12} lg={6}>
                            <div className="hero-slide-left justify-content-end align-items-center text-center text-lg-start">
                                <h2>Better Life Through</h2>
                                <h1>Better Metabolic Health</h1>
                                <p className="mb-xs-5">Let us assist with your weight loss journey.</p>
                                <div className="banner-btn m-sm-auto">
                                    <Link to="https://pp-wfe-100.advancedmd.com/158133/onlinescheduling/v2/patients"><button className="theme-btn btn-fill">Appoinment</button></Link>
                                    <Link to="/about"><button className='theme-btn bth-blank'>Learn More</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} lg={6} className="mt-sm-5">
                            <div className="hero-slide-right text-center text-lg-start mt-sm-5">
                                <div className="animate-img">
                                    <img src={scale} alt="" className="img-fluid aimg2" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
    );
};

export default Banner;