import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Content.css';

const Content = () => {
    return (
        <section className="about-content-sec">
            <Container>
                <Row>
                    <Col md={12} lg={8} lg={{ order: 2 }} className="text-center">
                        <div className="section-title">
                            <h1>Our Practice</h1>
                        </div>
                        <p className="content-inner">At Peony Health, we are dedicated to providing top-quality healthcare services tailored to your needs. Whether it's our origin story, the amazing individuals in our team, the recognition we've received, or capturing memorable moments through photos, we ensure that every aspect of our journey reflects our commitment to excellence.</p>
                        <p className="content-inner">We understand that embarking on a wellness journey is a personal and unique experience for each individual. Our approach combines the essence of personalized care with the ease and efficiency of modern convenience.</p>
                        <p className="content-inner">We believe that your wellness goals are as individual as you are. That's why Peony Health is committed to tailoring our services to meet your specific needs, ensuring that you receive the attention and support you deserve along every step of your journey.</p>
                        <p className="content-inner">With Peony Health, you can expect a seamless and convenient experience. From easily accessible online platforms to streamlined appointment scheduling, we prioritize making your wellness journey as smooth and stress-free as possible.</p>
                        <p className="content-inner">Let Peony Health be your dedicated partner in achieving your wellness goals. Discover the perfect blend of personalized care and modern convenience with us today.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Content;