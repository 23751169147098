export const FakeFeatures = [
    {
        "id": "001",
        "title": "Easy Appointment",
        "description": "Appointments are quick and easy",
        "img": "https://img.icons8.com/?size=80&id=XvcrKwMkRBlw&format=png"
    },
    {
        "id": "002",
        "title": "Personalized",
        "description": "Plans and prescriptions are personalized just for you!",
        "img": "https://img.icons8.com/?size=80&id=nixnMewGIJTF&format=png"
    },
    {
        "id": "003",
        "title": "Achieve Your Goals",
        "description": "Let us assit you in acchieving your health goals.",
        "img": "https://img.icons8.com/?size=80&id=P89GMsAG68NJ&format=png"
    }
]


